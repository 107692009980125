import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Accordion, Button, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSkull } from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../modules/common/components/seo';
import { SectionHeader } from '../../modules/common/components/section-header';

import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

interface ITogCharacterNodes {
  nodes: ITOGCharacter[];
}

interface ITogCharacterEntry {
  allCharacters: ITogCharacterNodes;
}

interface IProps {
  data: ITogCharacterEntry;
}

import './tier-list.scss';
import { ITOGCharacter } from '../../modules/common/model/graphql-types';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { TOGCharacter } from '../../modules/tog/common/components/tog-character';

const ToGTierListPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'R',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_r.png"
                height={24}
                alt="R rarity"
              />
            )
          },
          {
            value: 'SR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_sr.png"
                height={24}
                alt="SR rarity"
              />
            )
          },
          {
            value: 'SSR',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ssr.png"
                height={24}
                alt="SSR rarity"
              />
            )
          },
          {
            value: 'SSR+',
            image: (
              <StaticImage
                src="../../images/tog/icons/rarity_ssrplus.png"
                height={24}
                alt="SSR+ rarity"
              />
            )
          }
        ]
      },
      {
        key: 'type',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/tog/icons/type_phys.png"
                width={24}
                alt="Physical"
              />
            )
          },
          {
            value: 'Magical',
            tooltip: 'Magical',
            image: (
              <StaticImage
                src="../../images/tog/icons/type_magic.png"
                width={24}
                alt="Magical"
              />
            )
          }
        ]
      },
      {
        key: 'color',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Yellow',
            tooltip: 'Yellow',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_yellow.png"
                width={24}
                alt="Yellow"
              />
            )
          },
          {
            value: 'Purple',
            tooltip: 'Purple',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_purple.png"
                width={24}
                alt="Purple"
              />
            )
          },
          {
            value: 'Red',
            tooltip: 'Red',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_red.png"
                width={24}
                alt="Red"
              />
            )
          },
          {
            value: 'Blue',
            tooltip: 'Blue',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_blue.png"
                width={24}
                alt="Blue"
              />
            )
          },
          {
            value: 'Green',
            tooltip: 'Green',
            image: (
              <StaticImage
                src="../../images/tog/icons/color_green.png"
                width={24}
                alt="Green"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_tank.png"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          },
          {
            value: 'Assassin',
            tooltip: 'Assassin',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_assassin.png"
                width={24}
                alt="Assassin"
              />
            )
          },
          {
            value: 'Ranged',
            tooltip: 'Ranged',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_ranged.png"
                width={24}
                alt="Ranged"
              />
            )
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_mage.png"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/tog/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
      if (activeFilters.color && activeFilters.color !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.color.toLowerCase() === activeFilters.color.toLowerCase()
        );
      }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'low', label: 'Low' },
    { value: 'high', label: 'High' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke tog-tier '}
      game="tog"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/tog/categories/category_tier.png"
            alt="Tier"
          />
        </div>
        <div className="page-details">
          <h1>Tower of God: New World Tier list</h1>
          <h2>
            Tier list for Tower of God: New World that rates all characters.
          </h2>
          <p>
            Last updated: <strong>18/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-page nikke">
        <SectionHeader title="Tier List" />
        <Accordion defaultActiveKey="0" className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Introduction</Accordion.Header>
            <Accordion.Body>
              <h5>Special thanks</h5>
              <p>
                The tier list was created with the help of{' '}
                <strong>Conowen</strong> who shared his thoughts about the
                characters and their performance with us. You can find his
                YouTube channel{' '}
                <a
                  href="https://www.youtube.com/@Conowen96"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{' '}
                and his discord{' '}
                <a
                  href="https://discord.com/invite/gVDnRNa6ey"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                . On his channel, Conowen is posting reviews for every new
                character, guides for bosses and he even does account reviews on
                his Discord. Do check him out!
              </p>
              <h5>About the tier list</h5>
              <p>
                The tier list rates characters based on their{' '}
                <strong>Adventure</strong> performance. We're assuming{' '}
                <strong className="red">x5 deficit</strong> which means that the
                enemy team's power is 5 times higher than your team's power. We
                also decided to remove the Raid category as every boss requires
                a specialized team created to counter it - instead we've{' '}
                <strong>
                  marked the characters who are used against at least one boss
                </strong>{' '}
                with{' '}
                <FontAwesomeIcon icon={faSkull} className="red" width="18" />{' '}
                this icon. We will soon create a guide where we list the teams
                used on bosses!
              </p>
              <p>
                Also, please compare characters within their Color. ToG: New
                World is a very team-building focused game and if you create a
                team made out of random Colored characters but with higher
                rarity it may perform worse than a mono or duo Colored team. Use
                the switcher to change to the tier list you're interested in -
                the <strong>Story</strong> one is the one you will see by
                default.
              </p>
              <p>Criteria:</p>
              <ul>
                <li>
                  <strong>SSR</strong> characters are rated at Rainbow tier
                  (+21) with their Exclusive Equipment upgraded to +30,
                </li>
                <ul>
                  <li>
                    Still, some characters work well even before that and in
                    that case we have marked the minimum investment needed in
                    the top right corner,
                  </li>
                </ul>
                <li>
                  <strong>SSR+</strong> characters are rated based on their
                  minimum investment level to make them work. You can find the
                  investment level in the top right corner of their icon - for
                  example R3 means that you need to upgrade the character to Red
                  3 tier to get the performance matching the rating. Also,
                  similar to SSR characters, we're assuming that the Exclusive
                  Equipment is upgraded to +30.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Changelog</Accordion.Header>
            <Accordion.Body>
              <h5>18.09.2024</h5>
              <p>Added recently released characters to the tier list.</p>
              <h5>31.08.2024</h5>
              <p>Added recently released characters to the tier list.</p>
              <h5>19.08.2024</h5>
              <p>Added recently released characters to the tier list.</p>
              <h5>13.08.2024</h5>
              <p>
                Updated the tier list to match latest changes made by Conowen.
              </p>
              <h5>27.07.2024</h5>
              <p>Added Yirang Yeon [Great Family] to the tier list.</p>
              <h5>20.07.2024</h5>
              <p>
                Fully revamped the tier list. Now we rate characters only in
                Adventure and each character receives two ratings - up to
                Revolution 3 and up to Revolution 6, so showing low and high
                investment. We have also introduced SS tier for the strongest
                characters in the game - the two recently SSR+ characters are
                above the others (the powercreep is real).
              </p>
              <h5>23.06.2024</h5>
              <p>Added Kaiser [Ruler] to the tier list.</p>
              <h5>17.06.2024</h5>
              <p>Added Vivi [Daughter Slayer] to the tier list.</p>
              <h5>27.05.2024</h5>
              <p>Added Ha Jinsung [Ancient Monster] to the tier list.</p>
              <h5>14.05.2024</h5>
              <p>Added Ha Yura [Idol] to the tier list.</p>
              <h5>30.04.2024</h5>
              <p>Added Rak [Awakened] to the tier list.</p>
              <h5>20.04.2024</h5>
              <p>Added Hwaryun [Red Mask] to the tier list.</p>
              <h5>06.04.2024</h5>
              <ul>
                <li>
                  <strong>Khun Edahn</strong> was upgraded from A to S in both
                  Story and PVP.
                </li>
                <ul>
                  <li>
                    Edahn is a solid DPS unit, useful against full-ranged comps
                    and high-hp enemies in adventure. He is not a priority to
                    level up, because Yellow already has good DPS units and
                    there are more important SSR+ units to level up first.
                    Ranged units benefit a ton from Revolution.
                  </li>
                </ul>
                <li>
                  <strong>Shibisu</strong> was upgraded from C to A in both
                  Story and PVP.
                </li>
                <ul>
                  <li>
                    Shibisu gets really good in adventure once you can get him
                    to more than 95% resistance to keep him alive. He is going
                    to help with the focus of some ranged units, and reduce the
                    enemy energy regen and their resistances.
                  </li>
                </ul>
                <li>
                  <strong>Khun Mascheny [Lightning Spear]</strong> was upgraded
                  from A to S in both Story and PVP.
                </li>
                <ul>
                  <li>
                    Mascheny got an indirect buff with the new Regular Lero Ro
                    and the Revolution system being super good on Ranged units.
                    She might go up in the priority of SSR+ units to dupe first.{' '}
                  </li>
                </ul>
                <li>
                  <strong>Narae Seonwoo</strong> was upgraded from B to A in
                  both Story and PVP.
                </li>
                <ul>
                  <li>
                    Narae doesn't have a bad kit, but the invincibility she can
                    provide is too random and lasts for a short time. There are
                    some niche comps where she excels and can still be used
                    overall.
                  </li>
                </ul>
                <li>
                  <strong>Ghost</strong> was upgraded from C to B in Story.
                </li>
                <ul>
                  <li>
                    Ghost could be useful if Mascheny starts getting used,
                    because then his Magic Resistance shred would have a use in
                    Purple. Still to be tested, but there is potential.
                  </li>
                </ul>
                <li>
                  <strong>Hwaryun</strong> was upgraded from B to A in Story.
                </li>
                <ul>
                  <li>
                    Hwaryun used to be a great DPS option, but now she can
                    mostly be a support unit. There is a good tech comp with
                    Michael for which she might deserve a higher rank, but for
                    now, she'll stay here.
                  </li>
                </ul>
                <li>
                  <strong>Shilial [Red Snake]</strong> was upgraded from B to A
                  in both Story and PVP.
                </li>
                <ul>
                  <li>
                    Hwaryun used to be a great DPS option, but now she can
                    mostly be a support unit. There is a good tech comp with
                    Michael for which she might deserve a higher rank, but for
                    now, she'll stay here.
                  </li>
                </ul>
                <li>
                  <strong>Khun Ran [Lightning Pill] </strong> was upgraded from
                  A to S in both Story and PVP.
                </li>
                <ul>
                  <li>
                    Even after the collab, Khun Ran is quite good, he can
                    survive for a long time and the single-target damage he
                    deals is relevant. The new Crit set is made for him and
                    Regular Lero Ro boosted his damage even more - now this guy
                    is more viable than ever.
                  </li>
                </ul>
              </ul>
              <h5>01.04.2024</h5>
              <p>
                Added Yuri [Regular] and Lero Ro [Regular] to the tier list.
              </p>
              <h5>16.03.2024</h5>
              <p>Added Vicente to the tier list.</p>
              <h5>06.03.2024</h5>
              <p>Added Shilial [Red Snake] to the tier list.</p>
              <h5>29.02.2024</h5>
              <p>
                Updated the deficit from x3 to x5 - after the recent story
                difficulty nerfs, it's much easier to progress.
              </p>
              <h5>26.02.2024</h5>
              <p>The tier list has been fully revamped.</p>
              <h5>23.02.2024</h5>
              <ul>
                <li>
                  Added the 7DS collab characters to the tier list. Their
                  ratings are provisional as we're still testing them - all
                  three are somewhere between S and S+ (closer to S+ honestly),
                  but just to be safe, we placed Elizabeth and Meliodas in S for
                  now while Escanor received the S+ rating,
                </li>
                <li>
                  <strong>[The One] Escanor</strong> is the strongest of the
                  collab characters, who actually works decently well at even
                  lower dupe investment. Still, the more dupes of him you will
                  be able to obtain, the scarier he will become. Escanor is a
                  bruiser who can take a beating and also do it himself, on top
                  of buffing his allies via the passive (making them much harder
                  to kill). He is the cornerstone of all Blue teams and
                  melee-focused team moving forward,
                </li>
                <li>
                  <strong>[Eternal Rebirth] Elizabeth</strong> damage output is
                  great and she has a cheat death build into her kit, which is
                  rare for a damage dealer - and she can trigger it up to 4
                  times too over the battle. She also benefits from running a
                  full Blue team which after this collab became one of the
                  strongest elements,
                </li>
                <li>
                  <strong>[Cursed Shackles] Meliodas</strong> is everything Hatz
                  wishes he was - in the current state of the game and ever to
                  be honest. Meliodas can deal great burst damage and keep
                  himself alive really well (after he uses his Ultimate) but
                  unless you have him maxed (Rainbow), he will often die before
                  he can use his Ultimate and underperform compared to both
                  Elizabeth and Escanor. He really needs the bonus energy that
                  comes from the Rainbow perk to work well. If you don't have
                  him maxed, you can easily drop him down a tier or two on the
                  tier list if you're under a big deficit.
                </li>
              </ul>
              <h5>12.02.2024</h5>
              <ul>
                <li>
                  <strong>[Dual Swords] Hatz</strong> ratings decreased across
                  the board to C tier. Now that the tier list rates character
                  with 3x deficit, he simply can't compete anymore with other
                  characters. You served us well Hatz, but now it's time for you
                  to retire,
                </li>
                <li>
                  <strong>[Lightning Spear] Khun Mascheny</strong> dropped from
                  S to A tier in Story. At higher floors there's too many enemy
                  setups that counter her, making her harder to use,
                </li>
                <li>
                  <strong>[Mad Dog's Angel] Ron Mei</strong> dropped from S to A
                  tier in Story, from S to A in PVP. At bigger deficits her
                  healing isn't good enough to keep your team alive - especially
                  when you reach the point where your frontline gets one-shot.
                  Which makes her only good with frontline characters that have
                  some form of cheat death to bring them back up once it
                  triggers,
                </li>
                <li>
                  <strong>[Punisher] Ren</strong> dropped from A to B tier in
                  Story, from A to B in PVP. Sadly he's not as good as we
                  initially thoughts,
                </li>
                <li>
                  <strong>[Blooming Flower] Miseng Yeo</strong> rose from A to S
                  tier in Story, from A to S in PVP. Wth the release of Rihanna
                  set, she actually became pretty great with as she can counter
                  quite a lot of frontline characters in both Story and PVP,
                </li>
                <li>
                  <strong>[Infernal Blademaster] Shane</strong> dropped from A
                  to C tier in Story, from B to C against Bosses. Similar to
                  Hatz, she has issues staying alive in the frontlines at bigger
                  deficit.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="banner tog-promo">
          <h4>Play Tower of God: New World on PC</h4>
          <OutboundLink
            href="https://www.ldplayer.net/games/tower-of-god-new-world-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
            target="_blank"
          >
            <Button variant="primary">Play now on LDPlayer</Button>
          </OutboundLink>
        </div>
        <div className="tier-list-header">
          <p className="title">
            Tier List <span>(Adventure)</span>
          </p>
          <p className="sub-title">
            You're currently viewing the <strong>Adventure</strong> tier list.
            It shows how a character performs in Adventure{' '}
            {sortOption === 'low' &&
              'at low investment - so up to Revolution 3.'}
            {sortOption === 'high' &&
              'at high investment - so at Revolution 6.'}
          </p>
        </div>
        <div className="tier-list-switcher">
          <div
            className={`option story ${sortOption === 'low' && 'selected'}`}
            onClick={() => setSortOption('low')}
          >
            <p>Adventure (Up to Rev 3)</p>
          </div>
          <div
            className={`option pvp ${sortOption === 'high' && 'selected'}`}
            onClick={() => setSortOption('high')}
          >
            <p>Adventure (Rev 6)</p>
          </div>
        </div>
        <div className="employees-filter-bar nikke">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-tog">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-header purple">
                <span>Purple</span>
              </div>
              <div className="custom-header red">
                <span>Red</span>
              </div>
              <div className="custom-header blue">
                <span>Blue</span>
              </div>
              <div className="custom-header green">
                <span>Green</span>
              </div>
            </div>
          </div>
          {/* <div className="custom-tier tier-ss first">
            <div className="tier-rating ss">
              <span>SS</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '10')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div> */}
          <div className="custom-tier tier-s-plus first">
            <div className="tier-rating s-plus">
              <span>S+</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-s">
            <div className="tier-rating s">
              <span>S</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
          <div className="custom-tier tier-a">
            <div className="tier-rating a">
              <span>A</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-b">
            <div className="tier-rating b">
              <span>B</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-c">
            <div className="tier-rating c">
              <span>C</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
          <div className="custom-tier tier-d">
            <div className="tier-rating d">
              <span>D</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-e">
            <div className="tier-rating e">
              <span>E</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile yellow">
                <span>Yellow</span>
              </div>
              <div className="custom-tier-burst yellow">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Yellow')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile purple">
                <span>Purple</span>
              </div>
              <div className="custom-tier-burst purple">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Purple')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile red">
                <span>Red</span>
              </div>
              <div className="custom-tier-burst red">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Red')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile blue">
                <span>Blue</span>
              </div>
              <div className="custom-tier-burst blue">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Blue')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile green">
                <span>Green</span>
              </div>
              <div className="custom-tier-burst green">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.color === 'Green')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <TOGCharacter
                            slug={emp.slug}
                            mode="icon"
                            enablePopover
                            pvpMode
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ToGTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Tower of God: New World | Prydwen Institute"
    description="Tier list for Tower of God: New World that rates all characters."
    game="tog"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulTogCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        color
        type
        class
        rarity
        notReleased
        ratings {
          low
          high
        }
        goodOnBosses
        investment
      }
    }
  }
`;
